/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'

import { AiOutlineDelete, AiOutlineUser } from 'react-icons/ai'
import { RiAddLine, RiPencilLine } from 'react-icons/ri'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { Header } from '../../Components/Header'
import { SearchBox } from '../../Components/Header/SearchBox'
import { Pagination } from '../../Components/Pagination'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { BsEye } from 'react-icons/bs'
import NfeData from '../../Services/nfe-data'
import axios from 'axios'
import { useSearchParams } from 'react-router-dom'

export interface CategoriesProps {
  id: string
  name: string
  cover?: string
  description: string
  cover_url?: string
}

export interface VideoProps {
  id: string
  title: string
  description: string
  category_id: string
  cover?: string
  created_at: string
  cover_url?: string
}

export interface NfeProps {
  nfs_id: number
  num_nf: number
  dt_emissao: string
  cliente: string
  cnpj_cpf: string
  insc_estadual: string
  endereco: string
  bairro: string
  cep: number
  cidade: string
  uf: string
  fone: string
  vlr_sem_ipi: number
  vlr_ipi: number
  vlr_total: number
  existsPayments: boolean
}

const NfeList: FC = () => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [data, setData] = useState<any[]>([])
  const [dataFiltered, setDataFiltered] = useState([])
  const [error, setError] = useState(false)
  const [total, setTotal] = useState(10)
  const [selectedItem, setSelectedItem] = useState({} as NfeProps)
  const toast = useToast()
  const [searchStr, setSearchStr] = useState('')

  const isWideScreen = useBreakpointValue({
    base: false,
    lg: true
  })

  // useEffect(() => {
  //   const elements = NfeData.slice(0, 10)
  //   setData(elements)
  // }, [])

  const load = useCallback(async (pag: number, num_nf?: string) => {
    setLoading(true)
    try {
      const { data } = await api.get('/nfs', {
        params: {
          pag,
          num_nf
        }
      })

      setPage(pag)
      setData(data)
    } catch (error) {
      console.log('erro ao pegar nfe: ', error?.response?.data?.message)
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    load(page)
  }, [page])

  const handlePag = useCallback((pag: 'prev' | 'next') => {
    if (page === 1 && pag === 'prev') {
      setPage(1)
      return
    }

    if (pag === 'prev') {
      setPage(prev => prev - 1)
    }

    if (pag === 'next') {
      setPage(prev => prev + 1)
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Adicionando o 'smooth' para fazer uma animação suave
    })
  }, [page])

  const handleSearch = useCallback((e: any) => {
    if (e?.target?.value?.length) {
      setSearchStr(e?.target?.value)
    } else {
      setSearchStr('')
    }
  }, [])

  const submitSearch = useCallback(() => {
    console.log('submeteu: ', searchStr)

    if (searchStr?.length) {
      void load(1, searchStr)
    }
  }, [searchStr, load])

  return (
    <Box h="100vh" bg="gray.300">

      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px={['4', '4', '6']}>
        <Sidebar />

        <Box flex="1" borderRadius={8} bg="white" p="8">
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontWeight="normal" color="blue.500">
              Notas Fiscais
              <Text fontSize="17px" fontWeight="400">{`Página ${page}`}</Text>
            </Heading>

            {/* <SearchBox placeholder="Buscar por número da nota fiscal" onChangeFunction={(e) => handleFilter(e.target.value)} /> */}
            <SearchBox placeholder="Buscar por número da nota fiscal" onChangeFunction={e => handleSearch(e)} onSubmit={submitSearch} />
            {/* <SearchBox placeholder="Buscar por nome" /> */}

            {/* <Button
              as="a"
              size="sm"
              fontSize="sm"
              bg="blue.500"
              _hover={{ bgColor: 'gray.500' }}
              color="white"
              leftIcon={<Icon as={RiAddLine} fontSize="20" />}
              href="/categories/create"
            >
              Criar nova categoria
            </Button> */}

            {/* <NextLink href="/users/create" passHref>
                            <Button as="a" size="sm" fontSize="sm" colorScheme="blue" leftIcon={<Icon as={RiAddLine} fontSize="20" />}>
                                Criar Usuário
                            </Button>
                        </NextLink> */}
          </Flex>

          {loading ? (
            <Flex justify="center">
              <Spinner />
            </Flex>
          ) : error ? (
            <Flex justify="center">
              <Text>Erro ao carregar a lista</Text>
            </Flex>
          ) : (
            <>
              <Table colorScheme="blue">
                <Thead>
                  <Tr>
                    {/* <Th px={["4","4","6"]} color="gray.300" w="8">
                                        <Checkbox colorScheme="pink" />
                                    </Th> */}
                    <Th>Cliente</Th>

                    <Th w="8"></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.map((i) => (
                    <Tr key={i.nfs_id} backgroundColor={i.existsPayments ? '#d4ebf2' : '#FFF'}>
                      {/* <Td px={["4","4","6"]}>
                                         <Checkbox colorScheme="pink" />
                                     </Td> */}
                      <Td>
                        <Box>
                          <Link color="blue.500">
                            <Text fontWeight="bold">{i.cliente}</Text>
                          </Link>
                          <Text fontSize="sm" color="gray.300" fontWeight={i.existsPayments ? 'bold' : '400'}>
                            {i.cnpj_cpf}
                          </Text>
                          <Text fontSize="sm" color="gray.300" fontWeight={i.existsPayments ? 'bold' : '400'}>
                         NF número: {i.num_nf}
                          </Text>
                        </Box>
                      </Td>

                      <Td>
                        <Flex>
                          {isWideScreen
                            ? (
                            <Button
                              as="a"
                              href={`/nfe/create/${i.num_nf}?nfs_id=${i.nfs_id}&cnpj=${i.cnpj_cpf}`}
                              size="sm"
                              fontSize="sm"
                              mr="2"
                              colorScheme="green"
                              leftIcon={<Icon as={BsEye} fontSize="16" />}
                            >
                              Visualizar
                            </Button>
                              )
                            : (
                            <IconButton
                              mr="3"
                              colorScheme="green"
                              aria-label="Edit Content"
                              icon={<Icon as={RiPencilLine} size="30" />}
                            />
                              )}

                          {/* {isWideScreen ? (
                            <Button
                              as="button"
                              onClick={() => setSelectedItem(i)}
                              size="sm"
                              fontSize="sm"
                              colorScheme="red"
                              leftIcon={
                                <Icon as={AiOutlineDelete} fontSize="16" />
                              }
                            >
                              Excluir
                            </Button>
                          ) : (
                            <IconButton
                              colorScheme="red"
                              aria-label="Edit Content"
                              icon={<Icon as={AiOutlineDelete} size="30" />}
                              onClick={() => setSelectedItem(i)}
                            />
                          )} */}
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              <Flex pt={6} alignItems="center" justifyContent="center">

                <Button mr={3} onClick={() => handlePag('prev')}>Anterior</Button>
                <Text fontWeight="bold">{`Página ${page}`}</Text>
                <Button ml={3} onClick={() => handlePag('next')} >Próxima</Button>
              </Flex>

              {/* <Pagination
                totalCountOfRegisters={total}
                currentPage={page}
                onPageChange={setPage}
              /> */}
            </>
          )}
        </Box>
      </Flex>
    </Box>
  )
}

export { NfeList }
