import { ReactNode } from "react";
import { useAuth } from "../../Hooks/Auth";
import { validateUserPermissions } from "../../utils/validateUserPermissions";

interface Props {
  children: ReactNode;
  permissions?: string[];
  roles?: string[];
}
export function Can({ children, permissions, roles }: Props) {
  const { user } = useAuth();
  const hasPermission = validateUserPermissions({ user, permissions, roles });
  if (!hasPermission) {
    return null;
  }
  return <>{children}</>;
}
