import { Link as ChakraLink, Icon, Text } from "@chakra-ui/react";
import { ElementType } from "react";
import { useLocation, useRoutes } from "react-router-dom";

interface NavLinkProps {
  children: string;
  icon: ElementType;
  href: string;
}

export function NavLink({ children, icon, href, ...rest }: NavLinkProps) {
  const path = useLocation();

  return (
    // <ActiveLink href={href} passHref>
    //     <ChakraLink display="flex" alignItems="center" {...rest}>
    //         <Icon as={icon} fontSize="20" />
    //         <Text ml="4" fontWeight="medium">{children}</Text>
    //     </ChakraLink>
    // </ActiveLink>

    <ChakraLink href={href} display="flex" alignItems="center" {...rest}>
      <Icon
        as={icon}
        fontSize="20"
        color={path.pathname === href ? "whiteAlpha.900" : "gray.900"}
      />
      <Text
        ml="4"
        fontWeight="medium"
        color={path.pathname === href ? "whiteAlpha.900" : "gray.900"}
      >
        {children}
      </Text>
    </ChakraLink>
  );
}
