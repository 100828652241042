import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  // fonts: {
  //     heading: 'Roboto',
  //     body: 'Roboto'
  // },
  colors: {
    gray: {
      primary: "#1e1e1e",
      "900": "#181b23",
      "800": "#1f2029",
      "700": "#353646",
      "600": "#4b4d63",
      "500": "#616480",
      "400": "#797d9a",
      "300": "#9699b0",
      "200": "#b3b5c6",
      "100": "#d1d2dc",
      "50": "#eeeef2",
    },
    red: {
      primary: "#fe4345",
    },
  },
  styles: {
    global: {
      body: {
        bg: "gray.300",
        color: "gray.500",
      },
      button: {
        outline: 0,
        border: 0,
        outlineOffset: 0,
      },
      a: {
        outline: 0,
        border: 0,
      },
    },
  },
});
