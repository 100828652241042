import React, { FC } from 'react'
import { Header } from '../../Components/Header'
import { Sidebar } from '../../Components/Sidebar'
import { Flex, SimpleGrid, Box, Text, theme } from '@chakra-ui/react'

import { useAuth } from '../../Hooks/Auth'
import { TilesBox } from '../../Components/Tiles/TilesBox'
import { FiUsers } from 'react-icons/fi'
import { MdOutlineBusinessCenter, MdAttachMoney } from 'react-icons/md'
import { BsNewspaper } from 'react-icons/bs'

const Dashboard: FC = () => {
  const { user } = useAuth()

  //   useEffect(() => {
  //     api.get('/me').then(resp => {

  //     }).catch(error => console.log('error useEffect AuthProvider: ', error))
  // }, [])

  return (
    <Flex direction="column" h="100vh" bg="gray.300">
      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        <SimpleGrid
          flex="1"
          gap="4"
          minChildWidth="320px"
          alignItems="flex-start"
        >
          {/* <TilesBox
            title="Clientes"
            description="Total de clientes cadastrados."
            icon={FiUsers}
            value="800"
          /> */}

          {/* <TilesBox title="Parceiros" description="Total de contratantes." icon={MdOutlineBusinessCenter} value="90" iconStyle={{fontSize: 60}} />

                    <TilesBox title="Vagas" description="Total de oportunidades." icon={BsNewspaper} value="260" /> */}

          {/* <TilesBox
            title="Notas"
            description="Total de notas na plataforma"
            icon={MdAttachMoney}
            value="35"
          /> */}

          {/* <TilesBox
            title="Visualizações"
            description="Total de aulas visualizadas"
            icon={MdAttachMoney}
            value="R$ 670"
          /> */}

          {/* <TilesBox
            title="Ano"
            description="Total de faturamento neste ano."
            icon={MdAttachMoney}
            value="180.000,00"
          /> */}

          {/* <Box
                        p={["6","8"]}
                        bg="gray.800"
                        borderRadius={8}
                        pb="4"
                    >
                        <Text fontSize="lg" mb="4">
                            Inscritos da semana
                        </Text>

                        <Chart options={options} series={series} type="area" height={160} />
                    </Box>

                    <Box
                        p={["6","8"]}
                        bg="gray.800"
                        borderRadius={8}
                        pb="4"
                    >
                        <Text fontSize="lg" mb="4">
                            Taxa de abertura
                        </Text>
                        <Chart options={options} series={series} type="area" height={160} />
                    </Box> */}
        </SimpleGrid>
      </Flex>
    </Flex>
  )
}

export { Dashboard }
