import React, { FC } from 'react'
import { Routes, Route } from 'react-router-dom'

import { SignIn } from '../Pages/SignIn'
import { Dashboard } from '../Pages/Dashboard'
import { NotFoundPage } from '../Pages/NotFound'
import { useAuth } from '../Hooks/Auth'
import { CustomersList } from '../Pages/Customers'

import { NfeList } from '../Pages/Nfe'
import { NfeCreate } from '../Pages/Nfe/create'
import { CustomersCreate } from '../Pages/Customers/create'
import { CollaboratorsList } from '../Pages/Collaborators'
import { CollaboratorsCreate } from '../Pages/Collaborators/create'
import { LogsList } from '../Pages/Logs'

const AppRoutes: FC = () => {
  const { user } = useAuth()
  console.log('user: ', user)
  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/nfe" element={<NfeList />} />
      <Route path="/nfe/create" element={<NfeCreate />} />
      <Route path="/nfe/create/:num_nf" element={<NfeCreate />} />

      <Route path="/customers" element={<CustomersList />} />
      <Route path="/customers/create" element={<CustomersCreate />} />
      <Route
        path="/customers/create/:customer_id"
        element={<CustomersCreate />}
      />

      <Route path="/collaborators" element={<CollaboratorsList />} />
      <Route path="/collaborators/create" element={<CollaboratorsCreate />} />
      <Route
        path="/collaborators/create/:collaborator_id"
        element={<CollaboratorsCreate />}
      />

      <Route path="/logs" element={<LogsList />} />

      <Route path="/" element={<SignIn />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

export default AppRoutes
