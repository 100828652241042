import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'
import { FC, useCallback, useEffect, useState } from 'react'

import { AiOutlineDelete } from 'react-icons/ai'
import { RiAddLine, RiPencilLine } from 'react-icons/ri'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { Header } from '../../Components/Header'
import { Pagination } from '../../Components/Pagination'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'

export interface CustomerProps {
  id: string
  name: string
  cnpj: string
  royalty: string
}

const CustomersList: FC = () => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [data, setData] = useState<CustomerProps[]>([])
  const [error, setError] = useState(false)
  const [total, setTotal] = useState(10)
  const [selectedItem, setSelectedItem] = useState<CustomerProps>({} as CustomerProps)
  const toast = useToast()

  const isWideScreen = useBreakpointValue({
    base: false,
    lg: true
  })

  // useEffect(() => {
  //   const elements = Nfe.slice(0, 10)
  //   setData(elements)
  // }, [])

  const load = useCallback(async (pag: number) => {
    setLoading(true)
    try {
      const { data } = await api.get('/customers', {
        params: {
          pag
        }
      })

      console.log('load data: ', data)

      setPage(pag)
      setData(data.data)
      setTotal(data.total)
    } catch (error) {
      console.log('erro ao pegar administradores: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    load(page)
  }, [page])

  const handleDelete = useCallback(async () => {
    try {
      await api.delete(`/customers/${selectedItem?.id}`)

      toast({
        title: 'Deletado com sucesso',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
      setSelectedItem({} as CustomerProps)
      load(page)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setSelectedItem({} as CustomerProps)
    }
  }, [selectedItem, toast, page, load])

  const closeModal = useCallback(() => {
    setSelectedItem({} as CustomerProps)
  }, [])

  return (
    <Box>
      { selectedItem?.id && (
        <AlertDialogCustom
          title="Deletar Usuário"
          isOpen={!!selectedItem?.id}
          cancelFunction={closeModal}
          nextFunction={handleDelete}
        />
      )}
      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px={['4', '4', '6']}>
        <Sidebar />

        <Box flex="1" borderRadius={8} bg="white" p="8">
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontWeight="normal" color="blue.500">
              Clientes
              {/* { !loading && isRefetching && <Spinner size="sm" ml="4" color="pink.400" />} */}
            </Heading>

            {/* <SearchBox placeholder="Buscar por nome" /> */}

            <Button
              as="a"
              size="sm"
              fontSize="sm"
              bg="blue.500"
              _hover={{ bgColor: 'gray.500' }}
              color="white"
              leftIcon={<Icon as={RiAddLine} fontSize="20" />}
              cursor="pointer"
              href="/customers/create"
            >
              Criar novo Cliente
            </Button>

            {/* <NextLink href="/users/create" passHref>
                            <Button as="a" size="sm" fontSize="sm" colorScheme="blue" leftIcon={<Icon as={RiAddLine} fontSize="20" />}>
                                Criar Usuário
                            </Button>
                        </NextLink> */}
          </Flex>

          {loading ? (
            <Flex justify="center">
              <Spinner />
            </Flex>
          ) : error ? (
            <Flex justify="center">
              <Text>Erro ao carregar a lista</Text>
            </Flex>
          ) : (
            <>
              <Table colorScheme="blue">
                <Thead>
                  <Tr>
                    {/* <Th px={["4","4","6"]} color="gray.300" w="8">
                                        <Checkbox colorScheme="pink" />
                                    </Th> */}
                    <Th>Cliente</Th>

                    <Th w="8"></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.map((i) => (
                    <Tr key={i.id}>
                      {/* <Td px={["4","4","6"]}>
                                         <Checkbox colorScheme="pink" />
                                     </Td> */}
                      <Td>
                        <Box>
                          <Link color="blue.500">
                            <Text fontWeight="bold">{i.name}</Text>
                          </Link>
                          <Text fontSize="sm" color="gray.300">
                            {i?.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')}
                          </Text>
                        </Box>
                      </Td>

                      <Td>
                        <Flex>
                          {/* {isWideScreen ? (
                            <Button
                              as="button"
                              size="sm"
                              fontSize="sm"
                              mr="2"
                              colorScheme="teal"
                              leftIcon={
                                <Icon as={AiOutlineUser} fontSize="16" />
                              }
                            >
                              Perfil
                            </Button>
                          ) : (
                            <IconButton
                              mr="3"
                              colorScheme="teal"
                              aria-label="Edit Content"
                              icon={<Icon as={AiOutlineUser} size="30" />}
                            />
                          )} */}

                          {isWideScreen
                            ? (
                            <Button
                              as="a"
                              size="sm"
                              fontSize="sm"
                              mr="2"
                              colorScheme="green"
                              href={`/customers/create/${i.id}`}
                              leftIcon={
                                <Icon as={RiPencilLine} fontSize="16" />
                              }
                            >
                              Editar
                            </Button>
                              )
                            : (
                            <IconButton
                              mr="3"
                              colorScheme="green"
                              aria-label="Edit Content"
                              icon={<Icon as={RiPencilLine} size="30" />}
                            />
                              )}

                          {isWideScreen
                            ? (
                            <Button
                              as="button"
                              onClick={() => setSelectedItem(i)}
                              size="sm"
                              fontSize="sm"
                              colorScheme="red"
                              leftIcon={
                                <Icon as={AiOutlineDelete} fontSize="16" />
                              }
                            >
                              Excluir
                            </Button>
                              )
                            : (
                            <IconButton
                              colorScheme="red"
                              aria-label="Edit Content"
                              icon={<Icon as={AiOutlineDelete} size="30" />}
                              onClick={() => setSelectedItem(i)}
                            />
                              )}
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              <Pagination
                totalCountOfRegisters={total}
                currentPage={page}
                onPageChange={setPage}
              />
            </>
          )}
        </Box>
      </Flex>
    </Box>
  )
}

export { CustomersList }
