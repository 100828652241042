/* eslint-disable import/extensions */
import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Spinner,
  useToast,
  VStack
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { format, parseISO } from 'date-fns'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Input } from '../../Components/Form/Input'
import { InputMaskCustom } from '../../Components/Form/InputMask'
import { Select } from '../../Components/Form/Select'
import { Header } from '../../Components/Header'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { useLocation, useNavigate, useParams, useRoutes } from 'react-router-dom'
import { SessionProps } from '.'

interface OptionsProps {
  label: string
  value: string
}

const createUserFormSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  login: Yup.string().required('Login é obrigatório')
})

const CollaboratorsCreate: FC = () => {
  const [loading, setLoading] = useState(false)
  const [optionsGym, setOptionsGym] = useState<OptionsProps[]>([])
  const [showAddressComponent, setShowAddressComponent] = useState(false)
  const params = useParams()
  const navigate = useNavigate()

  const location = useLocation()
  const toast = useToast()

  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(createUserFormSchema)
  })
  const { errors } = formState

  const handleSubmitForm: any = useCallback(
    async (values: any) => {
      try {
        const {
          name,
          login
        } = values

        const payload = {
          name,
          login

        }

        const { collaborator_id } = params
        collaborator_id
          ? await api.put(`/session/${collaborator_id}`, payload)
          : await api.post('/session', payload)

        toast({
          title: `${collaborator_id ? 'Editado' : 'Cadastrado'} com sucesso`,
          position: 'top',
          isClosable: true,
          status: 'success',
          variant: 'solid'
        })
        navigate('/collaborators')
      } catch (error) {
        toast({
          title: error.response.data.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
      }
    },
    [navigate, toast, params]
  )

  const loadItem = useCallback(async () => {
    try {
      const resp = await api.get(`/session/${params?.collaborator_id}`)
      const data: SessionProps = resp.data

      setValue('name', data.name)
      setValue('login', data.login)
    } catch (error) {
      console.log('error edit: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, setValue, params])

  useEffect(() => {
    if (params?.collaborator_id) {
      loadItem()
    }
  }, [params])

  return (
    <Box>
      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        <Box
          as="form"
            onSubmit={handleSubmit(handleSubmitForm)}

          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
        >
          <Heading size="lg" fontWeight="normal" color="blue.500">
            Criar novo colaborador
          </Heading>
          <Divider my="6" borderColor="blue.700" />

          <Heading size="md" fontWeight="normal" color="blue.500" my="6">
            Dados Cadastrais
          </Heading>

          <VStack spacing={['6', '8']}>
            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              <Input
                label="Nome"
                placeholder="Preencha o nome"
                errors={errors.name}
                {...register('name')}
              />

<Input
                label="Login"
                placeholder="Preencha o login"
                errors={errors.login}
                {...register('login')}
              />
              {/* <Input
                label="cnpj"
                placeholder="Preencha o CNPJ do cliente"
                errors={errors.cnpj}
                {...register('cnpj')}
              /> */}

            </SimpleGrid>

          </VStack>

          <Flex mt={['6', '8']} justify="flex-end">
            <HStack>
              <Button
                as="a"
                bg="pink.500"
                _hover={{ bgColor: 'gray.500' }}
                color="white"
              >
                Cancelar
              </Button>

              <Button
                type="submit"
                bg="blue.500"
                _hover={{ bgColor: 'gray.500' }}
                color="white"
                isLoading={formState.isSubmitting}
              >
                Salvar
              </Button>
            </HStack>
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}

export { CollaboratorsCreate }
