import React, { FC, useCallback } from "react";
import { Flex, Button, Stack, Image } from "@chakra-ui/react";

import * as Yup from "yup";
import { useForm, SubmitHandler, UseFormHandleSubmit } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Input } from "../../Components/Form/Input";
import { useAuth } from "../../Hooks/Auth";

import Brand from "../../Assets/brand-big.png";

type SignInProps = {
  login: string;
  password: string;
};

const SignInSchemaValidation = Yup.object().shape({
  login: Yup.string().required("Login obrigatório"),
  password: Yup.string().required("Senha obrigatória"),
});

const SignIn: FC = () => {
  const { signIn } = useAuth();
  // const { handleSubmit } = useFormContext<SignInProps>();
  const { handleSubmit, register, formState } = useForm({
    resolver: yupResolver(SignInSchemaValidation),
  });
  const d = "hello";

  const { errors } = formState;

  const handleSignIn: SubmitHandler<any> = useCallback(
    async (values) => {
      const { login, password } = values;
      await signIn({ login, password });
    },
    [signIn]
  );

  const onError = (errors: any, e: any) => console.log(errors, e);

  return (
    <Flex w="100vw" h="100vh" align="center" justify="center" bg="gray.primary">
      <Flex
        as="form"
        w="100%"
        maxWidth={360}
        p="8"
        flexDir="column"
        borderRadius={16}
        onSubmit={handleSubmit(handleSignIn)}
        bg="gray.100"
      >
        <Stack spacing="8" color="white">
          <Image src={Brand} alt="Brand" w="70%" m="auto" mb="4" />
          <Input
            placeholder="Preencha seu Login"
            type="text"
            label="Login"
            {...register("login")}
          />
          <Input
            placeholder="Preencha sua senha"
            type="password"
            label="Senha"
            {...register("password")}
          />
        </Stack>
        <Button
          type="submit"
          size="lg"
          mt="10"
          bg="gray.primary"
          color="white"
          isLoading={formState.isSubmitting}
        >
          Entrar
        </Button>
      </Flex>
    </Flex>
  );
};

export { SignIn };
