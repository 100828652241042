import axios from 'axios'

// http://localhost:21333/api
// https://integralure.bellarte.com.br/api
const api = axios.create({

  baseURL:
    'https://integralure.bellarte.com.br/api'
})

export default api
