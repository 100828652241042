/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { FC, useRef, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Spinner,
  useToast,
  VStack,
  Text
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { format, isBefore, parseISO } from 'date-fns'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Input } from '../../Components/Form/Input'
import { TextArea } from '../../Components/Form/TextArea'
import { InputMaskCustom } from '../../Components/Form/InputMask'
import { Select } from '../../Components/Form/Select'
import { Header } from '../../Components/Header'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { useLocation, useParams, useNavigate, useSearchParams } from 'react-router-dom'
import {
  DragAndDrop,
  DragAndDropExportedProps
} from '../../Components/Form/DragAndDrop'
import Nfe from '../../Services/nfe-data'

import NfeDetail from '../../Services/nfe-details'
import NfePaymentSingle from '../../Services/nfe-pagamentos'

import NfeDetailMulti from '../../Services/nfe-details-multi'
import NfePaymentMulti from '../../Services/nfe-pagamentos-multi'

import { normalizeDate } from '../../utils/normalizeDate'
import { isNumberObject } from 'util/types'
import { substituirVirgulaPorPonto } from '../../utils/substituirVirgulaPorPonto'
import { FormatMoney } from '../../utils/formatMoney'

interface NfeProps {
  nfs_id: number
  num_nf: number
  cliente: string
  cnpj_cpf: number
  insc_estadual: number
  vlr_sem_ipi: number
  vlr_ipi: number
  vlr_total: number
}
interface NfeItem {
  nfs_id: number
  itnfs_id: number
  num_pedido: string
  linha: number
  cod_item: string
  desc_tecnica: string
  mascara_id: number
  mascara: string
  qtde: number
  preco_unit: number
  vlr_sem_ipi: number
  vlr_ipi: number
}

interface FoccoPayments {
  nfs_id: number
  num_nf: number
  parcela: string
  vencimento: string
  valor: string
}

const createFormSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  description: Yup.string().required('Descrição é obrigatório')
})

const updateFormSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  description: Yup.string().required('Descrição é obrigatório')
})

interface InstallmentsProps {
  id: string
  installment: string
  value: string
  date: string
  nf: string
  order: string
  omie_id: string
  omie_status: string
  dateFormatted: string
  status: 'A VENCER' | 'RECEBIDO'
}

interface CustomerValidProps {
  valid: boolean
  message: string
  customer_id?: string
  royalty?: number
}

let validateCustomerControl = false

const NfeCreate: FC = () => {
  const [loading, setLoading] = useState(false)
  const [loadingInstallments, setLoadingInstallments] = useState(true)
  const [loadingCustomerValidation, setLoadingCustomerValidation] = useState(true)
  const [customerValid, setCustomerValid] = useState<CustomerValidProps>({
    valid: true,
    message: ''
  })
  const [initialCover, setInitialCover] = useState(undefined)
  const [nfeDetail, setNfeDetail] = useState<NfeItem[]>([])
  const [nfe, setNfe] = useState<NfeProps>({} as NfeProps)
  const [nfePayment, setNfePayment] = useState([] as any)
  const [installments, setInstallments] = useState<InstallmentsProps[]>([])
  const [foccoPayments, setFoccoPayments] = useState<FoccoPayments[]>([])
  const params = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  const nfs_id = searchParams.get('nfs_id')
  const cnpj = searchParams.get('cnpj')

  const { register, handleSubmit, formState, getValues, setValue } = useForm({})

  const toast = useToast()

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const loadPayments = useCallback(async () => {
    setLoading(true)
    try {
      const { data } = await api.get(`/payments/${params.num_nf}`)
      console.log('installments num_nf: ', params.num_nf)
      console.log('installments: ', data.payments)
      setInstallments(data.payments)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      // setLoading(false)
    } finally {
      setLoading(false)
      setLoadingInstallments(false)
    }
  }, [toast, params])

  const loadItemsNfe = useCallback(async () => {
    try {
      console.log('loadItemsNfe num_nf: ', nfs_id)
      const { data } = await api.get(`/nfs/${nfs_id}`)
      console.log('loadItemsNfe data: ', data)
      setNfeDetail(data)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setLoading(false)
    } finally {
      setLoading(false)
      // setLoadingInstallments(false)
    }
  }, [toast, nfs_id])

  const loadNfe = useCallback(async () => {
    try {
      console.log('loadNfe: ')
      const { data } = await api.get('/nfs', {
        params: {
          pag: 1,
          num_nf: params?.num_nf
        }
      })
      console.log('loadNfe data: ', data)
      setNfe(data[0])
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setLoading(false)
    } finally {
      setLoading(false)
      // setLoadingInstallments(false)
    }
  }, [toast, params])

  // useEffect(() => {
  //   const nota = Nfe.find(i => String(i.num_nf) === String(params.nfe_id))
  //   console.log('nota: ', nota)
  //   setNfe(nota)

  //   // setNfe(Nfe.find(i => i.nfs_id === 305009))
  // }, [])

  // useEffect(() => {
  //   // setNfeDetail(NfeDetail)
  //   setNfeDetail(NfeDetailMulti)
  // }, [])

  const loadFoccoPayments = useCallback(async () => {
    try {
      console.log('loadFoccoPayments num_nf: ', nfs_id)
      const { data } = await api.get(`/nfs-payments/${nfs_id}`)

      if (!data) {
        toast({
          title: 'Não foi possível carregar os dados da focco',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
      }
      console.log('loadFoccoPayments data: ', data)

      const result = data?.map((i: any, index: number) => {
        const newValue = normalizeDate(i.vencimento)

        const parsed = parseISO(newValue)
        const vencimento = format(parsed, 'yyyy-MM-dd')
        const vencimentoOriginal = format(parsed, 'dd/MM/yyyy')

        // setValue(`vencimento-${index}`, vencimento)
        return {
          ...i,
          vencimento,
          vencimentoOriginal
        }
      })

      console.log('result: ', result)

      // carregando os pagamentos ja existentes no banco
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      loadPayments()

      setNfePayment(result)
      setFoccoPayments(data)
    } catch (error) {
      console.log('error: loadFoccoPayments', error)
      toast({
        title: error?.response?.data?.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      // setLoading(false)
    } finally {
      // setLoading(false)
      // setLoadingInstallments(false)
    }
  }, [toast, nfs_id, loadPayments])

  useEffect(() => {
    if (params.num_nf == null) return

    loadNfe()

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadItemsNfe()
    // eslint-disable-next-line react-hooks/exhaustive-deps, @typescript-eslint/no-floating-promises
    loadFoccoPayments()
  }, [params])

  // useEffect(() => {
  //   //  setNfePayment(NfePaymentSingle)

  //   const dados = NfePaymentMulti.map((i, index: number) => {
  //     const newValue = normalizeDate(i.vencimento)

  //     const parsed = parseISO(newValue)
  //     const vencimento = format(parsed, 'yyyy-MM-dd')
  //     const vencimentoOriginal = format(parsed, 'dd/MM/yyyy')

  //     // setValue(`vencimento-${index}`, vencimento)
  //     return {
  //       ...i,
  //       vencimento,
  //       vencimentoOriginal
  //     }
  //   })
  //   console.log('NfePayment: ', dados)

  //   setNfePayment(dados)
  // }, [])

  const handleEditBill = useCallback(async (installment: number) => {
    setLoading(true)
    try {
      let isValid = true
      let isValidAmount = true
      let errorMessage

      const values = getValues()

      const time = parseISO(values[`vencimento-${installment}`])

      const before = isBefore(time, new Date())

      if (before) {
        isValid = false
        errorMessage = 'Você não pode editar uma conta à receber com a data anterior a data atual. Confira os vencimentos.'
      }

      const currentValue = values[`valor-${installment}`]

      if (!currentValue || Number(currentValue) === 0 || isNaN(currentValue)) {
        isValidAmount = false
        errorMessage = 'Você não pode editar uma conta à receber com o valor vazio ou zero. Confira os valores dos recebimentos.'
      }

      const item = {
        installment: installment + 1,
        value: values[`valor-${installment}`],
        date: format(time, 'dd/MM/yyyy')
      }

      if (!isValid || !isValidAmount) {
        toast({
          title: errorMessage,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      const payload = {
        cnpj,
        customer_id: customerValid.customer_id,
        order: nfeDetail[0]?.num_pedido,
        payments: item
      }

      await api.put(`/payments/${installment + 1}`, payload)

      toast({
        title: 'Recebimento atualizado com sucesso na OMIE',
        position: 'top',
        isClosable: true,
        status: 'info',
        variant: 'solid'
      })
      loadPayments()
    } catch (error) {
      console.log('error: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }, [nfeDetail, toast, getValues, customerValid, cnpj, loadPayments])

  const handleCreatePayments: SubmitHandler<any> = useCallback(
    async (values) => {
      setLoading(true)
      try {
        let isValid = true

        const paymentsGroup = nfePayment?.map((_: any, index: number) => {
          const time = parseISO(values[`vencimento-${index}`])

          const before = isBefore(time, new Date())

          if (before) {
            isValid = false
          }

          return {
            installment: index + 1,
            value: values[`valor-${index}`],
            date: format(time, 'dd/MM/yyyy')
          }
        })

        if (!isValid) {
          toast({
            title: 'Você não pode criar uma conta à receber com a data anterior a data atual. Confira os vencimentos.',
            position: 'top',
            isClosable: true,
            status: 'error',
            variant: 'solid'
          })
          return
        }

        const payload = {
          cnpj,
          customer_id: customerValid.customer_id,
          nf: params?.num_nf,
          order: nfeDetail[0]?.num_pedido,
          payments: paymentsGroup
        }

        await api.post('/payments', payload)

        toast({
          title: 'Contas à receber enviadas para a fila. Em breve serão processadas para a OMIE',
          position: 'top',
          isClosable: true,
          status: 'info',
          variant: 'solid'
        })

        // loadPayments()
      } catch (error) {
        console.log('error: ', error)
        toast({
          title: error.response.data.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        setLoading(false)
      } finally {
        setLoading(false)
      }
    },
    [toast, nfePayment, params, cnpj, nfeDetail, customerValid]
  )

  // makePayment(i.valor, false, i)

  // const makePayment = useCallback((value: string, fromNumber: boolean, data?: any): any => {
  //   let validValue
  //   let existsInstallment = false
  //   const royalty = customerValid?.royalty
  //   const normalizeRoyalty = royalty ? royalty / 100 : 0

  //   // {((installments.find(installment => installment.installment === i.parcela)?.omie_id) != null) && (
  //   if (!installments?.length || !data) {
  //     validValue = value
  //   } else {
  //     existsInstallment = !(installments.find(installment => Number(installment.installment) === Number(data.parcela)) == null)
  //     validValue = installments.find(installment => installment.installment === data.parcela)?.omie_id != null
  //       ? installments.find(installment => Number(installment.installment) === Number(data.parcela))?.value
  //       : value
  //   }

  //   if (fromNumber && !existsInstallment) {
  //     console.log('validValue entrou 1 com valor: ', value)
  //     const money = Number(validValue)

  //     return (money * normalizeRoyalty).toFixed(2)
  //   } else {
  //     console.log('validValue entrou 2 com valor: ', value)

  //     if (existsInstallment && !!validValue) {
  //       console.log('existe a parcela: ', validValue)
  //       return Number(validValue).toFixed(2)
  //     }

  //     let money
  //     money = String(validValue)?.replace('.', '')
  //     money = money.replace(',', '.')
  //     money = Number(money)
  //     console.log('money: ', money)
  //     const t = existsInstallment ? money : money * normalizeRoyalty
  //     console.log('t: ', t)

  //     return t.toFixed(2)
  //   }
  // }, [installments, customerValid])

  const makePayment = useCallback((value: string, fromNumber: boolean, data?: any, index?: number): any => {
    const totalWithoutIPI = Number(nfe.vlr_sem_ipi)
    const totalWithIPI = Number(nfe.vlr_total)
    const customerRoyalty = customerValid?.royalty
    const royalty = customerRoyalty ? customerRoyalty / 100 : 0
    const totalInstallmentsFromNfe = foccoPayments?.length

    const normalizeValueNfe = totalWithoutIPI > totalWithIPI ? totalWithIPI : totalWithoutIPI

    console.log('totalInstallmentsFromNfe: ', totalInstallmentsFromNfe)

    if (fromNumber) {
      // calculo para colocar na label "valor total em royalties"
      const totalInRoyalties = normalizeValueNfe * royalty
      return FormatMoney(totalInRoyalties)
    } else {
      // calculo para colocar no input

      if (!installments?.length) {
        // não existe este pagamento ainda no sistema. Fazer cálculo a partir dos dados da focco
        const totalInRoyaltiesEachInstallment = (normalizeValueNfe * royalty) / totalInstallmentsFromNfe
        console.log('data: ', data)
        setValue(`valor-${index}`, totalInRoyaltiesEachInstallment.toFixed(2))
        return totalInRoyaltiesEachInstallment
      } else {
        // pegar dados à partir do pagamento já criado

        const existingValue = installments.find(installment => installment.installment === data.parcela)

        if (existingValue) {
          const normalizeExistingValue = Number(existingValue.value).toFixed(2)
          console.log('normalizeExistingValue: ', normalizeExistingValue)
          console.log('data: ', data)
          setValue(`valor-${index}`, normalizeExistingValue)
          return normalizeExistingValue
        } else {
          // fallback
          return 10
        }
      }
    }

    // let validValue
    // let existsInstallment = false
    // const royalty = customerValid?.royalty
    // const normalizeRoyalty = royalty ? royalty / 100 : 0

    // // {((installments.find(installment => installment.installment === i.parcela)?.omie_id) != null) && (
    // if (!installments?.length || !data) {
    //   validValue = value
    // } else {
    //   existsInstallment = !(installments.find(installment => Number(installment.installment) === Number(data.parcela)) == null)
    //   validValue = installments.find(installment => installment.installment === data.parcela)?.omie_id != null
    //     ? installments.find(installment => Number(installment.installment) === Number(data.parcela))?.value
    //     : value
    // }

    // if (fromNumber && !existsInstallment) {
    //   console.log('validValue entrou 1 com valor: ', value)
    //   const money = Number(validValue)

    //   return (money * normalizeRoyalty).toFixed(2)
    // } else {
    //   console.log('validValue entrou 2 com valor: ', value)

    //   if (existsInstallment && !!validValue) {
    //     console.log('existe a parcela: ', validValue)
    //     return Number(validValue).toFixed(2)
    //   }

    //   let money
    //   money = String(validValue)?.replace('.', '')
    //   money = money.replace(',', '.')
    //   money = Number(money)
    //   console.log('money: ', money)
    //   const t = existsInstallment ? money : money * normalizeRoyalty
    //   console.log('t: ', t)

    //   return t.toFixed(2)
    // }
  }, [installments, customerValid, nfe, foccoPayments])

  const makeButtonEdit = useCallback((i: any, index: number) => {
    const status = installments.find(installment => installment.installment === i.parcela)?.status

    if (status === 'A VENCER') {
      return <Button
        w="lg"
        ml="20"
        bg="blue.500"
        _hover={{ bgColor: 'gray.500' }}
        color="white"
        onClick={async () => await handleEditBill(index)}
        >
          Atualizar Recebimento
        </Button>
    }

    if (status === 'RECEBIDO') {
      return <Text w="100%" colorScheme="red.500" fontWeight="bold" ml="20">
        Não é possível editar recebimento já efetuado.
      </Text>
    }
  }, [installments, handleEditBill])

  const makeInstallment = useCallback((i: any) => {
    console.log('i: ', i)

    let existsInstallment

    if ((installments.find(installment => installment.installment === i.parcela)?.omie_id) != null) {
      console.log('achou a parcela: ', i.parcela)
      existsInstallment = installments.find(installment => installment.installment === i.parcela)?.date
    } else {
      existsInstallment = String(i.vencimento)
    }

    console.log('existsInstallment: ', existsInstallment)
    return existsInstallment
  }, [installments])

  const validateCustomer = useCallback(async (cnpj: string) => {
    setLoadingCustomerValidation(true)
    try {
      const { data: company } = await api.get(`/validation/customer/${cnpj}`)

      setCustomerValid({
        valid: true,
        message: '',
        customer_id: company.id,
        royalty: Number(company?.royalty)
      })
    } catch (error: any) {
      console.log('error: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setCustomerValid({
        valid: false,
        message: error?.response?.data?.message
      })
    } finally {
      setLoadingCustomerValidation(false)
    }
  }, [toast])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions

    if (!cnpj) return

    if (!validateCustomerControl) {
      validateCustomerControl = true
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      validateCustomer(cnpj)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box>

      {!customerValid.valid ? (
            <>
              <Header />

              <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
                <Sidebar />

                <Box
                  as="form"
                  flex="1"
                  borderRadius={8}
                  bg="white"
                  p="8"
                  onSubmit={handleSubmit(handleCreatePayments)}
                >
                  <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
                        <Text w="100%" colorScheme="blue" fontWeight="bold">
                          {customerValid?.message}
                        </Text>
                    </Flex>
                </Box>
              </Flex>
              </>

      ) : (
        <>
          <Header />

          <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
            <Sidebar />

            <Box
              as="form"
              flex="1"
              borderRadius={8}
              bg="white"
              p="8"
              onSubmit={handleSubmit(handleCreatePayments)}
            >
              <Heading size="lg" fontWeight="normal" color="blue.500">
                Detalhes da Nota | NF: {params?.num_nf} | Número do Pedido: {nfeDetail[0]?.num_pedido}
              </Heading>
              <Divider my="6" borderColor="blue.700" />

              {loadingCustomerValidation || loadingInstallments ? <Spinner /> : (
                <>
                <VStack spacing={['9', '9']} alignItems="flex-start">
                <Heading size="md" fontWeight="normal" color="blue.500">
                  Dados do Cliente
                </Heading>

                <Flex direction="column" w="100%" borderBottom="1px" paddingBottom="25px">
                  <VStack spacing={['6', '8']}>
                    <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
                      <Flex direction="column">
                        <Text w="100%" colorScheme="blue" fontWeight="bold">
                          Cliente
                        </Text>
                        <Text w="100%">
                          {nfe?.cliente}
                        </Text>
                      </Flex>

                      <Flex direction="column">
                        <Text w="100%" colorScheme="blue" fontWeight="bold">
                          CNPJ
                        </Text>
                        <Text w="100%">
                          {cnpj}
                        </Text>
                      </Flex>

                      <Flex direction="column">
                        <Text w="100%" colorScheme="blue" fontWeight="bold">
                        Inscrição Estadual
                        </Text>
                        <Text w="100%">
                          {nfe?.insc_estadual}
                        </Text>
                      </Flex>

                    </SimpleGrid>

                    <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
                    <Flex direction="column">
                        <Text w="100%" colorScheme="blue" fontWeight="bold">
                        Valor total da nota
                        </Text>
                        <Text w="100%">
                          {FormatMoney(nfe?.vlr_total)}
                        </Text>
                      </Flex>

                      <Flex direction="column">
                        <Text w="100%" colorScheme="blue" fontWeight="bold">
                        Valor total dos produtos
                        </Text>
                        <Text w="100%">
                          {FormatMoney(nfe?.vlr_sem_ipi)}
                        </Text>
                      </Flex>

                    </SimpleGrid>

                    <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
                    <Flex direction="column">
                        <Text w="100%" colorScheme="blue" fontWeight="bold">
                      Royalty Percentual
                        </Text>
                        <Text w="100%">
                          {customerValid?.royalty}%
                        </Text>
                      </Flex>

                      {nfe?.vlr_sem_ipi && (
                        <Flex direction="column">
                        <Text w="100%" colorScheme="blue" fontWeight="bold">
                        Valor total em Royalties
                        </Text>
                        <Text w="100%" color="red.500">
                          {makePayment(String(nfe?.vlr_sem_ipi), true)}
                        </Text>
                      </Flex>
                      )}

                    </SimpleGrid>
                  </VStack>
                </Flex>

                {loadingInstallments ? <Spinner /> : (
                  <>
                    <Heading size="md" fontWeight="normal" color="blue.500">
                  Pagamentos
                </Heading>
                {console.log('nfePayment: ', nfePayment)}
                {console.log('installments: ', installments)}

                {nfePayment?.map((i: any, index: number) => (
                    <Flex direction="column" w="100%" key={i?.parcela} borderBottom="1px" paddingBottom="25px">
                    <VStack spacing={['6', '8']}>
                      <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
                        <Flex direction="column">
                          <Text w="100%" colorScheme="blue" fontWeight="bold">
                            Parcela
                          </Text>
                          <Text w="100%">
                            {i?.parcela}
                          </Text>
                        </Flex>

                        <Flex direction="column">
                        {((installments.find(installment => installment.installment === i.parcela)?.omie_id) != null) && (
                          <>
                            <Text w="100%" colorScheme="blue" fontWeight="bold">
                            Vencimento original da nota(Focco)
                            </Text>
                            <Text w="100%" mb="10">
                              {i?.vencimentoOriginal}
                              {/* {installments.find(installment => installment.installment === i.parcela)?.date} */}
                            </Text>
                          </>
                        )}

                          <Text w="100%" colorScheme="blue" fontWeight="bold">
                            Vencimento na Omie
                          </Text>
                          <Input
                            type="date"
                            color="red.500"
                            {...register(`vencimento-${index}`)}
                              defaultValue={makeInstallment(i)}
                          />

                        </Flex>

                      </SimpleGrid>

                      <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

                      <Flex direction="column">
                          <Text w="100%" colorScheme="blue" fontWeight="bold">
                          Valor do Pagamento
                          </Text>
                          <Text w="100%">
                            R$ {i?.valor}
                          </Text>
                        </Flex>

                        {i?.valor && (
                          <Flex direction="column">
                          <Text w="100%" colorScheme="blue" fontWeight="bold">
                            Royalty a ser pago
                          </Text>

                          <Flex mt="8px" w="100%" >
                            {!loading && (
                            <Input
                            type="text"
                            color="red.500"
                            {...register(`valor-${index}`)}
                            leftAddon
                            leftAddonText="R$"
                            defaultValue={makePayment(i.valor, false, i, index)}
                            onKeyUp={(event) => {
                              const charCode = (event.which) ? event.which : event.keyCode
                              const input = event.target as HTMLInputElement
                              const value = input.value

                              if (charCode === 188) { // código da vírgula
                                input.value = value.replace(',', '.')
                              }
                            }}
                            />
                            )}

                          { loading ? <Spinner /> : makeButtonEdit(i, index)}

                  </Flex>

          </Flex>
                        )}

                      </SimpleGrid>
                    </VStack>
                  </Flex>
                ))}
                  </>
                )}

                <Heading size="md" fontWeight="normal" color="blue.500" >
                  Detalhes da nota
                </Heading>

                {nfeDetail?.map(i => (
                  <Flex direction="column" key={i?.itnfs_id} w="100%" >
                    <Heading
                        size="md"
                        fontWeight="normal"
                        color="blue.500"
                        w="100%"
                        mt="30"
                      >
                        {i?.desc_tecnica}
                    </Heading>

                    <Flex direction="column" w="100%">
                      <VStack spacing={['6', '8']}>
                        <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
                          <Flex direction="column">
                            <Text w="100%" colorScheme="blue" fontWeight="bold">
                              Código do Item
                            </Text>
                            <Text w="100%">
                              {i?.cod_item}
                            </Text>
                          </Flex>

                          <Flex direction="column">
                            <Text w="100%" colorScheme="blue" fontWeight="bold">
                              Quantidade
                            </Text>
                            <Text w="100%">
                              {i?.qtde}
                            </Text>
                          </Flex>

                        </SimpleGrid>

                        <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

                        <Flex direction="column">
                            <Text w="100%" colorScheme="blue" fontWeight="bold">
                            Valor total
                            </Text>
                            <Text w="100%">
                              {i?.vlr_sem_ipi}
                            </Text>
                          </Flex>

                        </SimpleGrid>
                      </VStack>
                    </Flex>
                  </Flex>
                ))}

              </VStack>

                {!installments?.length && (
                    <Flex mt={['6', '8']} justify="flex-end">
                    <HStack>
                      {loading
                        ? (
                        <Spinner />
                          )
                        : (
                        <>
                          {/* <Button
                            as="a"
                            bg="red.primary"
                            _hover={{ bgColor: 'gray.500' }}
                            color="white"
                          >
                            Cancelar
                          </Button> */}

                          <Button
                            type="submit"
                            bg="blue.500"
                            _hover={{ bgColor: 'gray.500' }}
                            color="white"
                            isLoading={formState.isSubmitting}

                          >
                            Gerar contas à receber
                          </Button>
                        </>
                          )}
                    </HStack>
                  </Flex>
                )}

                </>
              )}

            </Box>
          </Flex>
        </>
      )}

    </Box>
  )
}

export { NfeCreate }
