import { HStack, Icon, IconButton } from "@chakra-ui/react";
import { RiNotificationLine, RiUserAddLine } from "react-icons/ri";
import { AiOutlineLogout } from "react-icons/ai";
import { useAuth } from "../../Hooks/Auth";

export function NotificationsNav() {
  const { signOut } = useAuth();
  return (
    <HStack
      spacing={["6", "8"]}
      mx={["6", "8"]}
      pr={["6", "8"]}
      py="1"
      color="white"
      borderRightWidth={1}
      borderColor="white"
    >
      {/* <Icon as={RiNotificationLine} fontSize="20" />
            
            <Icon as={RiUserAddLine
            } fontSize="20" /> */}

      <IconButton
        icon={<Icon as={AiOutlineLogout} fontSize="20" />}
        onClick={signOut}
        aria-label="Sign Out"
        backgroundColor="transparent"
      />
    </HStack>
  );
}
