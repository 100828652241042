import { Flex, Input, Icon } from '@chakra-ui/react'
import { useCallback } from 'react'
import { RiSearch2Line } from 'react-icons/ri'

interface Props {
  placeholder?: string
  onChangeFunction?: (data?: any) => any
  onSubmit?: (data?: any) => any
}
export function SearchBox ({ placeholder, onChangeFunction, onSubmit }: Props) {
  const handleKeyDown = useCallback((event: any) => {
    if (event.key === 'Enter') {
      if (onSubmit != null) {
        onSubmit()
      }
    }
  }, [onSubmit])

  return (
    <Flex
      as="label"
      flex="1"
      py="4"
      px="8"
      ml="6"
      maxWidth={400}
      alignSelf="center"
      color="white"
      position="relative"
      bg="blue.500"
      borderRadius="full"
    >
      <Input
        name="search"
        color="white"
        variant="unstyled"
        px="4"
        mr="4"
        placeholder={placeholder}
        _placeholder={{ color: 'white' }}
        onChange={onChangeFunction}
        onKeyDown={handleKeyDown}
      />

      <Icon as={RiSearch2Line} cursor="pointer" fontSize="20" onClick={(onSubmit != null) ? async () => await onSubmit() : () => {}}/>
    </Flex>
  )
}
