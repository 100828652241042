/* eslint-disable import/extensions */
import React from 'react'
import { Stack } from '@chakra-ui/react'
import { CgGym } from 'react-icons/cg'
import { MdOutlineBusinessCenter, MdSecurity } from 'react-icons/md'
import {
  RiContactsLine,
  RiDashboardLine
} from 'react-icons/ri'
import { Can } from '../Can'
import { NavLink } from './NavLink'
import { NavSection } from './NavSection'

export function NavSidebar () {
  return (
    <Stack spacing="12" align="flex-start">
      <NavSection title="GERAL">
        <Can permissions={['all.list']}>
          <NavLink href="/dashboard" icon={RiDashboardLine}>
            Dashboard
          </NavLink>
        </Can>

        <Can permissions={['session.all']}>
          <NavLink href="/collaborators" icon={MdOutlineBusinessCenter}>
            Colaboradores
          </NavLink>
        </Can>

        <Can permissions={['all.list']}>
          <NavLink href="/customers" icon={CgGym}>
            Clientes
          </NavLink>
        </Can>

        <Can permissions={['logs.all']}>
          <NavLink href="/logs" icon={RiContactsLine}>
            Logs
          </NavLink>
        </Can>

        {/* <Can permissions={["all.list"]}>
          <NavLink href="/playlist" icon={HiOutlineDocumentText}>
            Produtos
          </NavLink>
        </Can> */}

        <Can permissions={['all.list']}>
          <NavLink href="/nfe" icon={MdSecurity}>
            Notas Fiscais
          </NavLink>
        </Can>

        {/* <Can permissions={["all.list"]}>
          <NavLink href="/playlist" icon={HiOutlineDocumentText}>
            Playlist
          </NavLink>
        </Can> */}

        {/*

        <Can permissions={["all.list"]}>
          <NavLink href="/holidays" icon={BsCalendarDate}>
            Feriados
          </NavLink>
        </Can>

        <Can permissions={["all.list"]}>
          <NavLink href="/hours" icon={AiOutlineClockCircle}>
            Horários
          </NavLink>
        </Can> */}

        {/* <Can permissions={["all.list"]}>
          <NavLink href="/users" icon={RiContactsLine}>
            Usuários
          </NavLink>
        </Can> */}

        {/* <Can permissions={["all.list"]}>
          <NavLink href="/schedule" icon={FaRegCalendarAlt}>
            Agenda
          </NavLink>
        </Can> */}
        {/* <NavLink href="/partners" icon={MdOutlineBusinessCenter}>Parceiros</NavLink>
                <NavLink href="/vacancys" icon={BsNewspaper}>Vagas</NavLink>
                <NavLink href="/financial" icon={MdAttachMoney}>Financeiro</NavLink> */}
      </NavSection>

      {/* <Can permissions={["all.list"]}>
        <NavSection title="APLICATIVO">

          <NavLink href="/app-config/create" icon={RiGitMergeLine}>
            Configurações
          </NavLink>
        </NavSection>
      </Can> */}
    </Stack>
  )
}
